import {
  fetchIndustries,
  createCompany,
  getCompanyById,
  updateCompany,
  fetchCompanies,
  getCompanyAdmins,
  addAdmin,
  updateCompanyAdmin,
  updateLicenses,
  getJobRole,
  createJobRole,
  updateJobRole,
  updateJobRoleTargets,
  changeUserStatus,
  saveUsersAsSellerApi,
  sendEmailToSelectedParticipants,
  getUser,
  getCoreCompetencies
} from '@/services/api/companies';
import { getProductById } from "@/services/api/products";
import { fetchSellerAdmins } from '@/services/api/people';
import { inviteSeller } from '@/services/api/account';
import { mockedJobRoles, mockedTargets } from './mocks';
import {
  getActiveAssessments,
  getCompanyUsers,
  getEmailsForActiveAssessment,
  getParticipantsForActiveAssessment,
  resendPlatformInvitation,
  updateSeller,
} from '../../../services/api/companies';

const getDefaultState = () => ({
  admins: [],
  totalCount: 0,
  adminsCount: 0,
  sellerAdminsCount: 0,
  licensesCount: 0,
  adminsNo: 0,
  companies: [],
  sellers: [],
  currentCompany: [],
  industries: [],
  industrySectors: [],
  jobRoleManagementMode: 'create',
  fetchAdmins: false,
  fetchSellers: false,
  jobRoles: mockedJobRoles,
  competencies: [],
  targets: mockedTargets,
  selectedJobRole: {},
  companyUsers: [],
  selectedUser: {},
  activeAssessments: [],
  selectedAssessment: {},
  participantsForSelectedAssessment: [],
  selectedParticipantsForSelectedAssessment: [],
  emailsForSelectedAssessment: [],
  totalUsersCount: 0,
});

const actions = {
  resetCallsState({ commit }) {
    commit('resetState');
  },
  getEmailsForActiveAssessment({ commit }, { surveyId, organizationId }) {
    return getEmailsForActiveAssessment(surveyId, organizationId)
      .then((rsp) => {
        let trimmed = rsp.data.data.filter((item) => item.surveyMessageType <= 5);
        commit('SET_EMAILS', trimmed);
      })
      .catch((err) => {});
  },
  getParticipantsForActiveAssessment({ commit }, { organizationId, surveyId, pageNr, pageSz }) {
    return getParticipantsForActiveAssessment(organizationId, surveyId, pageNr, pageSz).then((rsp) =>
      commit('SET_PARTICIPANTS', rsp.data.data)
    );
  },
  getCoreCompetencies({ commit }, payload) {
    return getCoreCompetencies(payload).then((rsp) => 
      commit('SET_COMPETENCIES', rsp.data.data)
    );
  },
  getActiveAssessments({ commit }, { organizationId }) {
    return getActiveAssessments(organizationId)
      .then((rsp) => commit('SET_ACTIVE_ASSESSMENTS', rsp.data.data))
      .catch((err) => {});
  },
  setSelectedAssessment({ commit }, { assessment }) {
    commit('SET_SELECTED_ASSESSMENT', assessment);
  },
  setSelectedParticipantsForSelectedAssessment({ commit }, participants) {
    commit('SET_SELECTED_PARTICIPANTS_FOR_SELECTED_ASSESSMENT', participants);
  },
  sendEmailToParticipants({ state, dispatch }, { surveyMessageId }) {
    const surveyParticipantIds = state.selectedParticipantsForSelectedAssessment.map((item) => item.id);
    return sendEmailToSelectedParticipants(surveyMessageId, surveyParticipantIds)
      .then((rsp) =>
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'Email Sent',
            message: 'Email notifications have been successfully sent.',
          },
          { root: true }
        )
      )
      .catch((err) => console.log(err));
  },
  updateSeller({ commit, dispatch, state }, { id, firstName, lastName, email }) {
    return updateSeller({ id, firstName, lastName, email })
      .then(() => {
        commit('SET_SELECTED_USER', {
          ...state.selectedUser,
          firstName: firstName,
          lastName: lastName,
          email: email,
        });
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'User details updated',
            message: 'The information for this user has been successfully updated.',
          },
          { root: true }
        );
      })
      .catch(() =>
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error',
            message: 'Email is already in use.',
          },
          { root: true }
        )
      );
  },
  changeUserStatus({ commit, dispatch, state }, { userId, isActive }) {
    return changeUserStatus(userId, isActive)
      .then(async () => {
        await getUser(userId).then((rsp) => {
          const user = rsp.data.data;
          commit('SET_SELECTED_USER', user);
          dispatch(
            'alerts/showSuccess',
            {
              messageHeader: isActive ? 'User Activated' : 'User Deactivated',
              message: isActive
                ? 'This user is now able to access their account on the platform.'
                : 'This user can no longer access their account on the platform.',
            },
            { root: true }
          );
        });
      })
      .catch(() =>
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error',
            message: 'Something went wrong, try again.',
          },
          { root: true }
        )
      );
  },
  saveUsersAsSeller({ dispatch }, { companyId, users }) {
    return saveUsersAsSellerApi(users, companyId).then((rsp) => {
      if (rsp.data.data.failedUsersCount > 0) {
        var failedUsersMessages = rsp.data.data.failedUsers.map(x => x.errorMessage);
          if(failedUsersMessages.some(x => x.includes("is already registered"))) {
            dispatch(
              'alerts/showInfo',
              {
                messageHeader: 'Some Users Were Not Imported',
                message:
                  'There were some email addresses in the file that already exist in our data base. Those users have not been imported.',
              },
              { root: true }
            );
        }
        if(failedUsersMessages.some(x => x.includes("Email cannot contain"))) {
          dispatch(
            "alerts/showInfo",
            {
              messageHeader: "Some Users Were Not Allowed",
              message:
                "There were some email addresses that contain characters that are not allowed. Those users have not been imported.",
            },
            { root: true }
          );
        }
        if(failedUsersMessages.some(x => x.includes("Invalid email address"))) {
          dispatch(
            "alerts/showInfo",
            {
              messageHeader: "Some users were not imported",
              message:
                `The format for ${failedUsersMessages.filter(x => x.includes("Invalid email address")).length} entries is not correct. Those users have not been imported.`,
            },
            { root: true }
          );
        }
      }
      if (rsp.data.data.importedUsersCount > 0) {
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'Users Added',
            message: 'The new users have been successfully added.',
          },
          { root: true }
        );
      }
    }).catch(err => {
      dispatch(
        'alerts/showError',
        {
          messageHeader: 'Users Upload Error',
          message: err.message,
        },
        { root: true }
      );
    });
  },
  resendInvitation({ dispatch, commit }, { id }) {
    return resendPlatformInvitation(id)
      .then(async () => {
        await getUser(id).then((rsp) => {
        const user = rsp.data.data;
        commit('SET_SELECTED_USER', user);
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'Invitation Email Has Been Sent',
            message: 'The user will receive shortly another email invitation to onboard the platform.',
          },
          { root: true }
        );
        return true;
      })})
      .catch((err) => {
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error',
            message: 'Account is already confirmed.',
          },
          { root: true }
        );
        return false;
      });
  },
  setSelectedUser({ commit }, { user }) {
    commit('SET_SELECTED_USER', user);
  },
  getIndustries({ commit }) {
    return fetchIndustries().then((rsp) => {
      commit('SET_INDUSTRIES', rsp.data.data);
      commit('SET_INDUSTRY_SECTORS');
    });
  },
  getJobRole({ dispatch }, { jobRoleId }) {
    return getJobRole(jobRoleId)
      .then((rsp) => dispatch('setSelectedJobRole', { jobRole: rsp.data.data }))
      .catch((err) =>
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error!',
            message: err.message,
          },
          { root: true }
        )
      );
  },
  updateJobRole({ dispatch, state }, { id, name, description, jobTitles }) {
    return updateJobRole(id, name, description, jobTitles)
      .then(() => {
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'Job Role Updated',
            message: 'The job role has been successfully updated.',
          },
          { root: true }
        );
        dispatch('setSelectedJobRole', {
          jobRole: { ...state.selectedJobRole, name, description, jobTitles },
        });
      })
      .catch((err) =>
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error!',
            message: err.message,
          },
          { root: true }
        )
      );
  },
  updateJobRoleTargets({ dispatch }, { id, targets }) {
    return updateJobRoleTargets(id, targets)
      .then(() => {
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'Job Role Targets Updated',
            message: 'The job role has been successfully updated.',
          },
          { root: true }
        );
        return true;
      })
      .catch((err) => {
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error!',
            message: err.message,
          },
          { root: true }
        );
        return false;
      });
  },
  async setJobRoleManagementMode({ commit, dispatch }, { mode, selectedJobRoleId }) {
    commit('SET_JOB_ROLE_MANAGEMENT_MODE', mode);
    if (mode === 'edit') {
      await dispatch('getJobRole', { jobRoleId: selectedJobRoleId });
    } else {
      dispatch('setSelectedJobRole', {
        jobRole: { id: 0, name: '', description: '', jobTitles: null },
      });
    }
  },
  createJobRole({ dispatch }, { name, description, jobTitles }) {
    return createJobRole(name, description, jobTitles)
      .then((rsp) => {
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'Job Role Created',
            message: 'The new job role has been successfully added to the system.',
          },
          { root: true }
        );
        dispatch('setSelectedJobRole', { jobRole: rsp.data.data });
      })
      .catch((err) =>
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error!',
            message: err.message,
          },
          { root: true }
        )
      );
  },
  getProductById({ dispatch }, { id, version }) {
    return getProductById(id, version).then((rsp) => {
      let {
        createdBy,
        id,
        canPublish,
        canPublishMessage,
        isReadOnly,
        productVersions,
        publishDate,
        name,
        currentVersion,
        survey,
        releaseNotes,
        draftVersion,
        endDate,
      } = rsp.data.data;
      
      let product = {
        createdBy,
        id,
        canPublish,
        canPublishMessage,
        isReadOnly,
        productVersions,
        publishDate,
        name,
        currentVersion,
        survey,
        releaseNotes,
        draftVersion,
        endDate,
      };
      return product;
    })
    .catch((err) =>
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error!',
            message: err.message,
          },
          { root: true }
        )
      );;
  },
  getCompanies({ commit }, { payload }) {
    return fetchCompanies(payload).then((rsp) => {
      commit('SET_COMPANIES', rsp.data.data);
      commit('SET_TOTAL_COMPANIES', rsp.data.totalCount);
      return rsp.data.data;
    });
  },

  create({ dispatch, commit }, { payload }) {
    return createCompany(payload)
      .then((rsp) => {
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'Success!',
            message: 'The company was created sucessfully.',
          },
          { root: true }
        );
        commit('SET_COMPANY', rsp.data.data);
        return rsp.data.data;
      })
      .catch((err) => {
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error!',
            message: err.message,
          },
          { root: true }
        );
      });
  },

  editCompany({ commit, dispatch }, { companyId, payload }) {
    return updateCompany(companyId, payload)
      .then((rsp) => {
        commit('SET_COMPANY', rsp.data.data);
      })
      .then(() => {
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'Success!',
            message: 'The company was edited sucessfully.',
          },
          { root: true }
        );
      })
      .catch((err) => {
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error!',
            message: err.message,
          },
          { root: true }
        );
      });
  },

  editCompanyAdmin({ commit, dispatch }, { id, payload }) {
    return updateCompanyAdmin(id, payload)
      .then(() => {
        dispatch('getCompanyDetails', { id });
        commit('SET_FETCH_ADMINS', true);
      })
      .then(() => {
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: payload.edit ? 'Admin Removed' : 'Success!',
            message: payload.edit
              ? 'The user has no longer admin rights to manage this company.'
              : 'The admin was updated sucessfully.',
          },
          { root: true }
        );
      })
      .catch((err) => {
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error!',
            message: err.message,
          },
          { root: true }
        );
      });
  },

  addLicenses({ commit, dispatch }, { id, payload }) {
    return updateLicenses(id, payload)
      .then((rsp) => {
        commit('SET_TOTAL_LICENSES', rsp.data.data.availableLicencesCount);
      })
      .then(() => {
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'Invites added!',
            message: 'The remaining invites count is now updated after adding new licenses to this company.',
          },
          { root: true }
        );
      })
      .catch((err) => {
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error!',
            message: err.message,
          },
          { root: true }
        );
      });
  },

  getCompanyDetails({ commit }, { id }) {
    return getCompanyById(id).then((rsp) => {
      commit('SET_COMPANY', rsp.data.data);
      commit('SET_TOTAL_ADMINS', rsp.data.data.adminsUsersCount);
      commit('SET_TOTAL_LICENSES', rsp.data.data.availableLicencesCount);
    });
  },

  fetchCompanyAdmins({ commit }, { id, payload }) {
    return getCompanyAdmins(id, payload).then((rsp) => {
      commit('SET_ADMINS', rsp.data.data);
      commit('SET_TOTAL_ADMINS', rsp.data.totalCount);
      commit('SET_FETCH_ADMINS', false);
      return rsp.data.data;
    });
  },
  fetchCompanyUsers({ commit }, { id, payload }) {
    return getCompanyUsers(id, payload).then((rsp) => {
      commit('SET_COMPANY_USERS', rsp.data.data);
      commit('SET_TOTAL_USERS_COUNT', rsp.data.totalCount);
      return rsp.data.data;
    });
  },
  addAdminToCompany({ commit, dispatch }, { id, payload }) {
    return addAdmin(payload)
      .then(() => {
        dispatch('getCompanyDetails', { id });
        commit('SET_FETCH_ADMINS', true);
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'New Admin Invited!',
            message: 'The company administrator has been created and an email invitation has been sent.',
          },
          { root: true }
        );
      })
      .catch(() => {
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error!',
            message: `Email ${payload.email} is already registered.`,
          },
          { root: true }
        );
      });
  },
  addSellerAdmin({ commit, dispatch }, { payload }) {
    return inviteSeller(payload)
      .then(() => {
        commit('SET_FETCH_SELLERS', true);
        dispatch(
          'alerts/showSuccess',
          {
            messageHeader: 'New Admin Invited!',
            message: 'The administrator has been created and an email invitation has been sent.',
          },
          { root: true }
        );
      })
      .catch(() => {
        dispatch(
          'alerts/showError',
          {
            messageHeader: 'Error!',
            message: `Email ${payload.email} is already registered.`,
          },
          { root: true }
        );
      });
  },

  getSellerAdmins({ commit }, { keyword, pageNr, sorting }) {
    return fetchSellerAdmins(keyword, pageNr, sorting).then((rsp) => {
      commit('SET_SELLERS', rsp.data.data);
      if (!keyword) commit('SET_TOTAL_SELLERS', rsp.data.totalCount);
      commit('SET_FETCH_SELLERS', false);
      return rsp;
    });
  },
  setSelectedJobRole({ commit }, { jobRole }) {
    commit('SET_SELECTED_JOB_ROLE', jobRole);
  },
  resetSelectedJobRole({ commit }) {
    commit('SET_SELECTED_JOB_ROLE', {
      id: Math.random(),
      name: '',
      mapping: [],
      description: '',
      active: true,
      targets: [
        { name: 'Business Acumen', id: Math.random(), target: null },
        { name: 'Category Management', id: Math.random(), target: null },
        { name: 'CSR and Ethics', id: Math.random(), target: null },
        { name: 'Cost and Price Management', id: Math.random(), target: null },
        { name: 'Financial Analysis', id: Math.random(), target: null },
      ],
    });
  },
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_PARTICIPANTS(state, payload) {
    state.participantsForSelectedAssessment = payload;
  },
  SET_EMAILS(state, payload) {
    state.emailsForSelectedAssessment = payload;
  },
  SET_COMPETENCIES(state, payload) {
    state.competencies = payload;
  },
  SET_SELECTED_ASSESSMENT(state, payload) {
    state.selectedAssessment = payload;
  },
  SET_SELECTED_PARTICIPANTS_FOR_SELECTED_ASSESSMENT(state, payload) {
    state.selectedParticipantsForSelectedAssessment = payload;
  },
  SET_SELECTED_USER(state, payload) {
    state.selectedUser = payload;
  },
  SET_SELECTED_JOB_ROLE(state, payload) {
    state.selectedJobRole = payload;
  },
  SET_JOB_ROLE_MANAGEMENT_MODE(state, payload) {
    state.jobRoleManagementMode = payload;
  },
  SET_INDUSTRIES(state, industries) {
    state.industries = industries;
  },
  SET_INDUSTRY_SECTORS(state) {
    const sector = state.industries.map((sector) => sector.industrySector);
    state.industrySectors = sector;
  },
  SET_TOTAL_USERS_COUNT(state, payload) {
    state.totalUsersCount = payload;
  },
  SET_COMPANY(state, company) {
    state.currentCompany = company;
  },
  SET_COMPANIES(state, companies) {
    state.companies = companies;
  },
  SET_ADMINS(state, admins) {
    state.admins = admins;
  },
  SET_SELLERS(state, sellers) {
    state.sellers = sellers;
  },
  SET_TOTAL_ADMINS(state, adminsCount) {
    state.adminsCount = adminsCount;
  },
  SET_TOTAL_SELLERS(state, sellerAdminsCount) {
    state.sellerAdminsCount = sellerAdminsCount;
  },
  SET_TOTAL_LICENSES(state, licenses) {
    state.licensesCount = licenses;
  },
  SET_TOTAL_COMPANIES(state, totalCount) {
    state.totalCount = totalCount;
  },
  SET_FETCH_ADMINS(state, fetchAdmins) {
    state.fetchAdmins = fetchAdmins;
  },
  SET_FETCH_SELLERS(state, fetchSellers) {
    state.fetchSellers = fetchSellers;
  },
  SET_COMPANY_USERS(state, payload) {
    state.companyUsers = payload;
  },
  SET_ACTIVE_ASSESSMENTS(state, payload) {
    state.activeAssessments = payload;
  },
};

const getters = {
  jobRoleManagementMode: (state) => state.jobRoleManagementMode,
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
