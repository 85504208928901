import { apiCall } from "./api";

export const getTeam = (id) => apiCall("GET", `api/v1/Team/${id}`);

export const fetchTeams = () => apiCall("GET", `api/v1/Team/for_manager`);

export const fetchAdminTeams = (onlyActive, pageNr, pageSz) => {
  const active = `&OnlyActiveTeams=${!!onlyActive}`;
  const pageSize = `&pageSize=${pageSz || "100000"}`;
  const pageNumber = `pageNumber=${pageNr || "1"}`;
  return apiCall(
    "GET",
    `api/v1/Team/for_admin?${pageNumber}${pageSize}${active}`
  );
};

export const fetchTeamsIds = () =>
  apiCall("GET", "api/v1/Team/ids_for_manager");

export const fetchUsers = (id, keyword = "", pageNr, sorting = null) => {
  const body = { keyword, pageNumber: pageNr, pageSize: 10, sorting };
  return apiCall("POST", `api/v1/Team/${id}/members`, body);
};

export const fetchAllMembers = (id) =>
  apiCall("POST", `api/v1/Team/${id}/members`, {
    surveyId: null,
    jobRoleId: null,
    pageSize: 100000,
  });

export const reviewTeam = (id) =>
  apiCall("POST", `api/v1/Team/${id}/review`, { teamId: id });

export const fetchMembersBySurvey = (teamId, surveyId, jobRoleId) => {
  return apiCall("POST", `api/v1/Team/${teamId}/members`, {
    surveyId: parseInt(surveyId, 10),
    jobRoleId: parseInt(jobRoleId, 10),
    pageSize: 100000,
  });
};

export const createTeam = (payload) => apiCall("POST", "api/v1/team", payload);

export const fetchManagers = () =>
  apiCall("GET", "api/v1/organization/all-managers");

export const assignManager = (id, managerId) =>
  apiCall("POST", `api/v1/Team/${id}/assign-manager`, { userId: managerId });

export const assignUser = (teamId, userId) =>
  apiCall("POST", `api/v1/Team/${teamId}/assign-users`, { userId, teamId });

export const removeUser = (teamId, userId) =>
  apiCall("POST", `api/v1/Team/${teamId}/remove-user`, { userId });

export const getTeamById = (teamId) => apiCall("GET", `api/v1/Team/${teamId}`);

export const removeManager = (teamId, managerId) =>
  apiCall("POST", `api/v1/Team/${teamId}/remove-manager`, { managerId });

export const updateTeam = (teamId, payload) =>
  apiCall("PUT", `api/v1/Team/${teamId}`, payload);

export const getMembersByTeamId = (teamId, payload) =>
  apiCall("POST", `api/v1/Team/${teamId}/members`, { ...payload });

export const fetchUsersOutsideTeam = (payload) =>
  apiCall("POST", `api/v1/User/not-belonging-to-team`, payload);

export const deactivateTeam = (id) =>
  apiCall("PUT", `api/v1/Team/${id}/deactivate`);

export const activateTeam = (id) =>
  apiCall("PUT", `api/v1/Team/${id}/activate`);
