import * as invitesApi from '../../services/api/invites';
import idGenerator from '@/services/utils/idGenerator.js';
import Vue from 'vue';
import moment from 'moment';
import _orderBy from 'lodash/orderBy';

const getDefaultState = () => ({
  invitesHistory: [],
  responsibleQuery: '',
  allowUnlimitedInvites: false,
  invitesInfo: null,
  allInvitesInfo: null,
  allProductsInvitesInfo: [],
  allOrgs: {
    keyword: '',
    startDate: null,
    endDate: null,
    sorting: [-1, -1, -1, -1, -1],
  },
  productsPayload: {
    startDate: null,
    endDate: null
  },
  history: {
    sorting: [-1],
  },
  companies: [],
  products: [],
  redeemedInvites: 0,
});

const actions = {
  getInvitesHistoryByOrg({ commit, state }, orgId) {
    return invitesApi.getInvitesHistory(orgId, state.responsibleQuery).then((response) => {
      commit(
        'SET_INVITES_HISTORY',
        response.data.data.map((inviteHistory) => {
          let activity, role, noOfInvites;
          switch (inviteHistory.activityType) {
            case 0:
              activity = 'Added';
              break;
            case 1:
              activity = 'Removed';
              break;
            case 2:
              activity = 'Redeemed';
              break;
            case 3:
              activity = 'Returned';
              break;
            case 4:
              activity = inviteHistory.isInvitesNumberUnlimited ? 'Added' : 'Returned';
              break;
            default:
              break;
          }

          switch (inviteHistory.userRoles[0]) {
            case 'SellerAdmin':
              role = 'ISM Seller Admin';
              break;
            case 'CompanyAdmin':
              role = 'Company Admin';
              break;
            default:
              break;
          }
          // REFACTOR to switch eventually
          if (inviteHistory.isInvitesNumberUnlimited) {
            if (inviteHistory.activityType === 3) {
              noOfInvites = inviteHistory.numberOfInvitesForActivity;
            } else if (inviteHistory.activityType === 2) {
              noOfInvites = inviteHistory.numberOfInvitesForActivity * -1;
            } else {
              noOfInvites = 'Unlimited';
            }
          } else if (inviteHistory.activityType === 4) {
            noOfInvites = inviteHistory.totalRemaining;
          } else if (inviteHistory.activityType === 3) {
            noOfInvites = inviteHistory.numberOfInvitesForActivity;
          } else {
            noOfInvites =
              inviteHistory.activityType !== 0
                ? inviteHistory.numberOfInvitesForActivity * -1
                : inviteHistory.numberOfInvitesForActivity;
          }

          return {
            uuid: idGenerator(),
            date: moment(inviteHistory.modificationDate).format('MMM DD, YYYY'),
            originalDate: inviteHistory.modificationDate,
            noOfInvites,
            activity,
            remaining: inviteHistory.isInvitesNumberUnlimited ? 'Unlimited' : inviteHistory.totalRemaining,
            responsibleName: inviteHistory.userName,
            role,
          };
        })
      );
    });
  },
  async setResponsible({ commit, dispatch }, { responsible, orgId }) {
    commit('SET_RESPONSIBLE', responsible);
    await dispatch('getInvitesHistoryByOrg', orgId);
  },
  async addInvites({ dispatch }, { orgId, nrOfLicenses }) {
    await invitesApi.addInvites(orgId, nrOfLicenses).then(async () => {
      dispatch(
        'alerts/showSuccess',
        {
          messageHeader: 'Invites Added',
          message: 'The remaining invites count is now updated after adding new invites to this company.',
        },
        { root: true }
      );
      await dispatch('getOrgInvitesInfo', orgId);
      await dispatch('getInvitesHistoryByOrg', orgId);
    });
  },
  async removeInvites({ dispatch }, { orgId, nrOfLicenses }) {
    await invitesApi.removeInvites(orgId, nrOfLicenses).then(async () => {
      dispatch(
        'alerts/showSuccess',
        {
          messageHeader: 'Invites Removed',
          message: 'The remaining invites count is now updated after a number of them have been removed.',
        },

        { root: true }
      );
      await dispatch('getOrgInvitesInfo', orgId);
      await dispatch('getInvitesHistoryByOrg', orgId);
    });
  },
  allowUnlimitedInvites({ commit, dispatch }, { orgId, val }) {
    return invitesApi.setUnlimitedInvites(orgId, val).then(async () => {
      commit('SET_UNLIMITED', val);
      await dispatch('getInvitesHistoryByOrg', orgId);
    });
  },
  getOrgInvitesInfo({ commit, rootGetters }, orgId) {
    const tokenOrgId = rootGetters['auth/getOrganizationId'];
    const id = isNaN(tokenOrgId) ? orgId : tokenOrgId;
    return invitesApi.getOrgInvitesInfo(id).then((response) => {
      commit('SET_ORG_INVITES_INFO', response.data.data);
      commit('SET_UNLIMITED', response.data.data.isInvitesNumberUnlimited);
    });
  },
  getAllInvitesInfo({ commit }, type) {
    invitesApi.getAllInvitesInfo(type).then((response) => {
      commit('SET_ALL_INVITES_INFO', response.data.data);
    });
  },
  getAllProductsInvitesInfo({ commit }) {
    invitesApi.getAllProductsInvitesInfo().then((response) => {
      commit(
        "SET_ALL_PRODUCTS_INVITES_INFO",
        response.data.data.map((item) => ({
          ...item,
          label: item.product,
        }))
      );
      commit('SET_REDEEMED_INVITES', response.data.data?.[0]?.invitesRedeemed)
    });
  },
  getAllOrgsInfo({ commit, state }) {
    const payload = {
      pageNumber: 1,
      pageSize: 10000,
      keyword: state.allOrgs.keyword,
      startDate: state.allOrgs.startDate,
      endDate: state.allOrgs.endDate,
    };
    return invitesApi.getAllOrgsInfo(payload).then((response) => {
      commit(
        'SET_ALL_ORGS_INFO',
        response.data.data.map((company) => ({
          uuid: company.organizationId,
          companyName: company.organizationName,
          purchased: company.numberOfInvitesPurchased,
          redeemed: company.numberOfInvitesRedeemed,
          remaining: company.isNumberOfInvitesUnlimited ? Number.MAX_SAFE_INTEGER : company.numberOfInvitesRemaining,
          isUnlimited: company.isNumberOfInvitesUnlimited,
          subscriptionStatus: company.status,
        }))
      );
    });
  },
  async setOrgPayload({ commit, dispatch }, { field, value }) {
    await commit('SET_ORG_PAYLOAD', { field, value });
    dispatch('getAllOrgsInfo');
  },
  async setProductsPayload({ commit, dispatch }, { field, value, orgId }) {
    await commit('SET_PRODUCTS_PAYLOAD', { field, value });
    dispatch('getAllProductsInfo', orgId);
  },
  exportCompaniesCsv({ state }, parser) {
    const MAX = 900000000000;
    const csvPayload = state.companies.map((company) => ({
      'Company name': company.companyName,
      Purchased: company.purchased,
      Redeemed: company.redeemed,
      Remaining: company.remaining > MAX ? 'Unlimited' : company.remaining,
    }));
    const csv = parser.unparse(csvPayload);
    parser.download(csv, 'companies-invites-status');
  },
  exportProductsCsv({ state }, parser) {
    const csvPayload = state.products.map((product) => ({
      'Product Name': product.name,
      'Invites Redeemed': product.redeemed,
    }));
    const csv = parser.unparse(csvPayload);
    parser.download(csv, 'products-redeemed-count');
  },
  sortCompanies({ commit, state }, sortingIdx) {
    commit('SET_SORTING_VALUE', sortingIdx);
    const keys = {
      0: 'companyName',
      1: 'subscriptionStatus',
      2: 'purchased',
      3: 'redeemed',
      4: 'remaining',
    };
    const sortedList = _orderBy(
      state.companies,
      [keys[sortingIdx]],
      [state.allOrgs.sorting[sortingIdx] === -1 ? 'desc' : 'asc']
    );
    commit('SET_ALL_ORGS_INFO', sortedList);
  },
  sortHistory({ commit, state }, sortingIdx) {
    commit('SET_HISTORY_SORTING_VALUE', sortingIdx);
    const keys = {
      0: 'originalDate',
    };
    const sortedList = _orderBy(
      state.invitesHistory,
      [keys[sortingIdx]],
      [state.history.sorting[sortingIdx] === -1 ? 'desc' : 'asc']
    );
    commit('SET_INVITES_HISTORY', sortedList);
  },
  getAllProductsInfo({ commit, state }, orgId) {
    const payload = {
      organizationId: parseInt(orgId, 10),
      startDate: state.productsPayload.startDate || null,
      endDate: state.productsPayload.endDate || null,
    };
    invitesApi.getAllProductsInfo(payload).then((response) => {
      commit(
        'SET_ALL_PRODUCTS_INFO',
        response.data.data.organizationProductsInvitesRedeemed.map((product) => ({
          name: product.productName,
          redeemed: product.invitedRedeemedNumber,
        }))
      );
    });
  },
  resetCallsState({ commit }) {
    commit('resetState');
  },
  setRedeemedInvites({commit}, payload) {
    commit('SET_REDEEMED_INVITES', payload);
  },
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_INVITES_HISTORY(state, invites) {
    state.invitesHistory = invites;
  },
  SET_ORG_PAYLOAD(state, { field, value }) {
    state.allOrgs[field] = value;
  },
  SET_PRODUCTS_PAYLOAD(state, { field, value }) {
    state.productsPayload[field] = value;
  },
  SET_ALL_ORGS_INFO(state, companies) {
    Vue.set(state, 'companies', companies);
  },
  SET_RESPONSIBLE(state, responsible) {
    state.responsibleQuery = responsible;
  },
  SET_UNLIMITED(state, val) {
    state.allowUnlimitedInvites = val;
  },
  SET_ORG_INVITES_INFO(state, info) {
    state.invitesInfo = info;
  },
  SET_ALL_INVITES_INFO(state, info) {
    state.allInvitesInfo = info;
  },
  SET_ALL_PRODUCTS_INVITES_INFO(state, info) {
    state.allProductsInvitesInfo = info;
  },
  SET_SORTING_VALUE(state, sortingIdx) {
    state.allOrgs.sorting[sortingIdx] *= -1;
  },
  SET_HISTORY_SORTING_VALUE(state, sortingIdx) {
    state.history.sorting[sortingIdx] *= -1;
  },
  SET_ALL_PRODUCTS_INFO(state, products) {
    state.products = products;
  },
  SET_REDEEMED_INVITES(state, payload) {
    state.redeemedInvites = payload;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
