import { apiCall } from "./api";

export const fetchCompanyAssessments = () =>
  apiCall("GET", "api/v1/Survey/completed-surveys-for-admin");

export const fetchTeamsForAssessment = (surveyId) =>
  apiCall("GET", `api/v1/Team/admin-teams-for-survey/${surveyId}`);

export const fetchAssessmentResults = (
  surveyId,
  teamId,
  group,
  jobId,
  userId
) => {
  const comp = group === "competency";
  let params = `?SurveyId=${surveyId}&GroupUnderParent=${comp}`;
  if (teamId > -1) params += `&TeamId=${teamId}`;
  if (jobId) params += `&JobRoleId=${jobId}`;
  if (userId) params += `&UserId=${userId}`;
  return apiCall("GET", `api/v1/Competency/results_by_filters${params}`);
};

export const fetchSurveyStats = (surveyId, teamId, jobRoleId) => {
  let params = "?";
  if (teamId > -1) params += `&TeamId=${teamId}`;
  if (jobRoleId) params += `&JobRoleId=${jobRoleId}`;
  return apiCall("GET", `api/v1/Survey/${surveyId}/overall_progress${params}`);
};

export const fetchJobRoles = (surveyId, teamId) => {
  const params = teamId > -1 ? `?teamId=${teamId}` : "";
  return apiCall(
    "GET",
    `api/v1/survey/${surveyId}/job-roles-for-organization-team-members${params}`
  );
};

export const fetchMembersBySurvey = (teamId, surveyId, jobRoleId) => {
  return apiCall("POST", `api/v1/Organization/members`, {
    surveyId: parseInt(surveyId, 10),
    jobRoleId: parseInt(jobRoleId, 10),
    teamId: parseInt(teamId, 10),
  });
};

export const fetchProgress = (surveyId) =>
  apiCall("POST", `api/v1/Survey/${surveyId}/participants`, {
    pageSize: 100000,
    pageNumber: 1,
  });
