import { apiCall } from "./api";
import injectHtml from "../../views/AdminAssessments/utils/htmlTemplate";
import _forEach from "lodash/forEach";

export const fetchAvailableSurveys = () =>
  apiCall("GET", "api/v1/Organization/available_surveys");

export const saveUsers = (users, organizationId) => {
  if (!organizationId) {
    return apiCall("POST", "api/v1/User/import", users);
  } else {
    return apiCall(
      "POST",
      `api/v1/User/import_for_seller?organizationId=${organizationId}`,
      users
    );
  }
};

export const getJobRoles = (
  { pageNr, pageSz, keyword, assignedOnly } = {
    pageNr: 1,
    pageSz: 100000,
    keyword: "",
  }
) =>
  assignedOnly
    ? apiCall(
        "GET",
        `api/v1/JobRole?PageNumber=${pageNr}&PageSize=${pageSz}&Keyword=${keyword}&AssignedOnly=${assignedOnly}`
      )
    : apiCall(
        "GET",
        `api/v1/JobRole?PageNumber=${pageNr}&PageSize=${pageSz}&Keyword=${keyword}`
      );

export const getJobRolesForSurvey = (id) =>
  apiCall("GET", `api/v1/Survey/${id}/allowed-job-roles`);

export const beginSetup = (id) =>
  apiCall("POST", `api/v1/Survey/${id}/begin_setup`, {
    surveyId: id,
  });

export const getGeneralInfo = (id) => apiCall("GET", `api/v1/Survey/${id}`);

export const getSurveyLaunchAvalabilityInfo = (id) => apiCall("GET", `api/v1/Survey/${id}/get-survey-launch-avalability`);

export const saveGeneralInfo = (
  id,
  name,
  hasRandomisedQuestions,
  allowInMultipleSessions,
  allowGoingBack,
  resultsPermission,
  organizationId,
  restrictManagersResults,
  maxTimeAllocation,
) => {
  if (!organizationId) {
    return apiCall("PUT", `api/v1/Survey/${id}`, {
      id,
      name: name,
      description: "",
      hasRandomisedQuestions: hasRandomisedQuestions,
      allowInMultipleSessions: allowInMultipleSessions,
      allowGoingBack: allowGoingBack,
      resultsPermission: resultsPermission,
      restrictManagersResults: restrictManagersResults,
      maxTimeAllocation,
    });
  } else {
    return apiCall(
      "PUT",
      `api/v1/Survey/${id}/for-seller?organizationId=${organizationId}`,
      {
        id,
        name: name,
        description: "",
        hasRandomisedQuestions: hasRandomisedQuestions,
        allowInMultipleSessions: allowInMultipleSessions,
        allowGoingBack: allowGoingBack,
        resultsPermission: resultsPermission,
        restrictManagersResults: restrictManagersResults,
        maxTimeAllocation,
      }
    );
  }
};

export const saveDates = (
  id,
  openingTime,
  closingTime,
  timezone,
  organizationId
) => {
  if (!organizationId) {
    return apiCall("PUT", `api/v1/Survey/${id}`, {
      id,
      openingTime,
      closingTime,
      timezone,
      description: "",
    });
  } else {
    return apiCall(
      "PUT",
      `api/v1/Survey/${id}/for-seller?organizationId=${organizationId}`,
      {
        id,
        openingTime,
        closingTime,
        timezone,
        description: "",
      }
    );
  }
};

export const launchSurvey = (id, organizationId) => {
  if (!organizationId) {
    return apiCall("PUT", `api/v1/Survey/${id}`, {
      id,
      description: "",
      state: 1,
    });
  } else {
    return apiCall(
      "PUT",
      `api/v1/Survey/${id}/for-seller?organizationId=${organizationId}`,
      {
        id,
        description: "",
        state: 1,
      }
    );
  }
};

export const getUsersFromOrganization = (
  id,
  pageNr,
  pageSz,
  keyword,
  sorting
) =>
  apiCall("POST", `api/v1/User`, {
    surveyId: id,
    pageNumber: pageNr,
    pageSize: pageSz,
    keyword: keyword,
    sorting: sorting,
  });

export const getParticipants = (
  id,
  pageNr,
  pageSz,
  keyword,
  sorting,
  organizationId
) => {
  if (!organizationId) {
    return apiCall("POST", `api/v1/Survey/${id}/participants`, {
      surveyId: id,
      pageNumber: pageNr,
      pageSize: pageSz,
      keyword: keyword,
      sorting: sorting,
    });
  } else {
    return apiCall(
      "POST",
      `api/v1/Survey/${id}/participants_for_seller?organizationId=${organizationId}`,
      {
        surveyId: id,
        pageNumber: pageNr,
        pageSize: pageSz,
        keyword: keyword,
        sorting: sorting,
      }
    );
  }
};

export const addParticipants = (id, users, organizationId) => {
  if (!organizationId) {
    return apiCall("POST", `api/v1/Survey/${id}/setup_new_participants`, {
      surveyId: id,
      userIds: users,
    });
  } else {
    return apiCall(
      "POST",
      `api/v1/Survey/${id}/setup_new_participants_for_seller?organizationId=${organizationId}`,
      {
        surveyId: id,
        userIds: users,
      }
    );
  }
};

export const updateJobRoles = (id, jobRoles) =>
  apiCall("POST", `api/v1/Survey/${id}/update_participants_settings`, {
    surveyId: id,
    participantsSettings: jobRoles,
  });

export const updatePermissions = (id, permissions) =>
  apiCall("POST", `api/v1/Survey/${id}/update_participants_settings`, {
    surveyId: id,
    participantsSettings: permissions,
  });

export const getIndividualTargets = (id) =>
  apiCall("GET", `api/v1/Target/by_survey/${id}`);

export const getOrganizationalTargets = (id) =>
  apiCall("GET", `api/v1/Target/by_survey/organization/${id}`);

export const getCompetencyLevels = (id) =>
  apiCall("GET", `api/v1/Survey/${id}/score-settings`);

export const updateTargets = (id, targets) =>
  apiCall("PUT", `api/v1/Target/by_survey/${id}`, {
    surveyId: id,
    competencyTargets: targets,
  });

export const fetchMessages = (id, organizationId) => {
  if (!organizationId) {
    return apiCall("GET", `api/v1/Survey/${id}/get_survey_communication`);
  } else {
    return apiCall(
      "GET",
      `api/v1/Survey/${id}/get_survey_communication_for_seller?organizationId=${organizationId}`
    );
  }
};

export const saveMessages = (id, messages, organizationId) => {
  _forEach(messages, (el) => {
    el.htmlMessage = injectHtml(el.message);
  });
  if (!organizationId) {
    return apiCall("PUT", `api/v1/Survey/${id}/update_survey_communication`, {
      surveyId: id,
      messages,
    });
  } else {
    return apiCall(
      "PUT",
      `api/v1/Survey/${id}/update_survey_communication_for_seller?organizationId=${organizationId}`,
      {
        surveyId: id,
        messages,
      }
    );
  }
};

export const resetTargetsToBenchmarks = (id) =>
  apiCall("POST", `api/v1/Target/by_survey/${id}/reset_to_default`, {
    surveyId: id,
  });

export const setLastCompletedStep = (
  id,
  step,
  forceUpdate = false,
  organizationId
) => {
  if (!organizationId) {
    return apiCall("PATCH", `api/v1/Survey/${id}/change-last-updated-step`, {
      lastUpdatedStep: step,
      forceUpdate,
    });
  } else {
    return apiCall(
      "PATCH",
      `api/v1/Survey/${id}/change-last-updated-step-for-seller?organizationId=${organizationId}`,
      {
        lastUpdatedStep: step,
        forceUpdate,
      }
    );
  }
};

export const fetchDraftSurveys = () => apiCall("GET", "api/v1/Survey/drafts");

export const getAvailableLicenses = (organizationId) => {
  if (!organizationId) {
    return apiCall("GET", "api/v1/Organization/available_licences");
  } else {
    return apiCall(
      "GET",
      `api/v1/Organization/available_licenses-for-seller?organizationId=${organizationId}`
    );
  }
};

export const resetSurveyCommunicationMessage = (
  surveyId,
  messageId,
  organizationId
) => {
  if (!organizationId) {
    return apiCall(
      "PUT",
      `api/v1/Survey/${surveyId}/reset_survey_communication_message?messageId=${messageId}`
    );
  } else {
    return apiCall(
      "PUT",
      `api/v1/Survey/${surveyId}/reset_survey_communication_message_for_seller?messageId=${messageId}&organizationId=${organizationId}`
    );
  }
};

export const deleteCustomMessage = (surveyId, messageId, organizationId) => {
  if (!organizationId) {
    return apiCall(
      "DELETE",
      `api/v1/Survey/${surveyId}/delete-custom-email?surveyEmailId=${messageId}`
    );
  } else {
    return apiCall(
      "DELETE",
      `api/v1/Survey/${surveyId}/delete-custom-email-for-seller?surveyEmailId=${messageId}&organizationId=${organizationId}`
    );
  }
};

export const deleteDraftAssessment = (id) =>
  apiCall("DELETE", `api/v1/Survey/${id}/delete-draft`);

  export const getCommunicationLog = (id, keyword) => {
    var params = new URLSearchParams({
      keyword,
    })
    return apiCall("GET", `api/v1/Communication/${id}/communication-activitylog?${params.toString()}`);
  }